import { Injectable } from '@angular/core';
import { User } from '../models/auth.models';
import { getFirebaseBackend } from '../../firebaseBackend/authUtils';
import { getFirebaseBackend as getFirestoreBackend } from "../../firebaseBackend/firestoreUtils";

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor() {}

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Send verification mail
     */
    sendVerificationMail(){
        return getFirebaseBackend().SendVerificationMail().then(()=>{
        })
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     * @param firstName firstName
     * @param lastName lastName
     */
    register(userData:any) {
        return getFirebaseBackend().registerUser(userData).then((response: any) => {
            const user = response;
            delete userData.password;
            this.checkAndGetUserByEmail(userData.email).then((doc: any) => {
                if (doc !== null) {
                    this.updateUserRecordByDeleteOldOne(doc, user.uid, userData);
                    if (doc.projects) {
                        this.updateProjectUsers(Object.keys(doc.projects), user.uid, doc.id);
                    }
                    if (doc.companies) {
                        this.updateCompanyUsers(Object.keys(doc.companies), user.uid, doc.id);
                    }
                }
                else {
                    this.creatNewUser(user.uid, userData)
                }
            })
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /** 
     * @param passowrd current password
     */
    checkCurrentPaswword(password:string){
        return getFirebaseBackend().checkCurrentPassword(password).then((response: Boolean)=>{
            return response
        })
    }
    /**
     * @param password new password
     */
    updatePassword(passowrd:string){
        return getFirebaseBackend().updatePassword(passowrd).then((res:any)=>{
            return res;
        })
    }

    /**
     * return user email verification status
     */
    isUserEmailVerified(){
        return getFirebaseBackend().isUserEmailVerified();
    }
    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }


    checkAndGetUserByEmail(email) {
        return getFirestoreBackend().getDocByUniqueProperty("users", "email", email).then((res) => {
            return res
        })
    }

    creatNewUser(uid: string, Data: any) {
        return getFirestoreBackend().createDocumentWithSetIDAndcollectionPath(uid, `/users`, Data).then((res: any) => {
            sessionStorage.setItem('Username', Data.firstName + ' ' + Data.lastName);
        })
    }

    updateUserRecordByDeleteOldOne(oldDoc: any, newUID: string, userData: any) {
        delete userData.email;
        const userOldDocPth = `users/${oldDoc.id}`;
        const newData = { ...userData, ...oldDoc };
        delete newData.id;
        getFirestoreBackend().deleteDocumentByDocPath(userOldDocPth);
        this.creatNewUser(newUID, newData);
    }

    updateProjectUsers(projectsList, newUid: string, oldUid: string) {
        projectsList.forEach(pid => {
            const projectDocPath = `projects/${pid}`;
            getFirestoreBackend().getDocumentByDocPath(projectDocPath).then((project: any) => {
                const projectIndex = project.users.findIndex((obj => obj == oldUid));
                project.users[projectIndex] = newUid;
                delete project.id
                getFirestoreBackend().editDocumentByDocPath(projectDocPath, project);
            })
        });
    }
    updateCompanyUsers(companyList, newUid: string, oldUid: string) {
        companyList.forEach(cid => {
            const companyDocPath = `companies/${cid}`;
            getFirestoreBackend().getDocumentByDocPath(companyDocPath).then((company: any) => {
                const companyIndex = company.users.findIndex((obj => obj == oldUid));
                company.users[companyIndex] = newUid;
                delete company.id
                getFirestoreBackend().editDocumentByDocPath(companyDocPath, company);
            })
        });
    }
}

